// Generated by Framer (353ff71)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["HQSnHQiKK", "t6VgY88Zt"];

const serializationHash = "framer-mLcvy"

const variantClassNames = {HQSnHQiKK: "framer-v-fhvehg", t6VgY88Zt: "framer-v-1be2xca"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.8, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "HQSnHQiKK", "Variant 2": "t6VgY88Zt"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "HQSnHQiKK"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "HQSnHQiKK", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear16sm9e3 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("t6VgY88Zt", true), 400)
})

const onAppear3zfxks = activeVariantCallback(async (...args) => {
await delay(() => setVariant("HQSnHQiKK", true), 400)
})

useOnVariantChange(baseVariant, {default: onAppear16sm9e3, t6VgY88Zt: onAppear3zfxks})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-fhvehg", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"HQSnHQiKK"} ref={refBinding} style={{background: "radial-gradient(50% 50% at 50% 50%, var(--token-5b4355ee-c6a9-4535-9c0d-4b183110acf9, rgb(255, 255, 255)) /* {\"name\":\"2025 White 100%\"} */ 0%, rgba(217, 217, 217, 0) 100%)", ...style}} variants={{t6VgY88Zt: {background: "radial-gradient(50% 50% at 50% 50%, var(--token-5b4355ee-c6a9-4535-9c0d-4b183110acf9, rgb(255, 255, 255)) /* {\"name\":\"2025 White 100%\"} */ 1.6663147522522521%, rgba(217, 217, 217, 0) 27.69918355855856%)"}}} {...addPropertyOverrides({t6VgY88Zt: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mLcvy.framer-5qsqxg, .framer-mLcvy .framer-5qsqxg { display: block; }", ".framer-mLcvy.framer-fhvehg { height: 32px; overflow: visible; position: relative; width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"t6VgY88Zt":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjTmXed6Pj: React.ComponentType<Props> = withCSS(Component, css, "framer-mLcvy") as typeof Component;
export default FramerjTmXed6Pj;

FramerjTmXed6Pj.displayName = "Flashing radar location dot";

FramerjTmXed6Pj.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerjTmXed6Pj, {variant: {options: ["HQSnHQiKK", "t6VgY88Zt"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerjTmXed6Pj, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})